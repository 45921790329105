import { Drawer } from "antd";
import NavMenu from "./NavMenu";
import React from "react";

interface Props {
  open: boolean;
  onClose: () => void;
}

const LayoutDrawer = (props: Props) => {
  const { open, onClose } = props;

  return (
    <Drawer
      open={open}
      placement={"left"}
      closeIcon={null}
      bodyStyle={{ padding: "0" }}
      headerStyle={{ display: "none" }}
      maskClosable
      onClose={onClose}
    >
      <NavMenu />
    </Drawer>
  );
};

export default LayoutDrawer;
