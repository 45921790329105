/* tslint:disable */
/* eslint-disable */
/**
 * 
 * 
 *
 * 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from "./configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';

globalAxios.interceptors.request.use(
config => {
if (config.url === '/api/user/login') {
return config;
}
config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
config.headers.tenantId = 'default'; //todo env

return config;
},
error => Promise.reject(error)
)

globalAxios.interceptors.response.use(function(response){
//Dosomethingwithresponsedata
return response;
},function(error){
//401
if(error.response?.status === 401){
localStorage.removeItem('token');
location.reload();
}
//todo all 500 check
if(error.response?.status >= 500){
console.log(error);
}

return Promise.reject(error);
});

//export const BASE_PATH = "http://localhost:3000".replace(/\/+$/, "");
export const BASE_PATH = "";

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: AxiosRequestConfig;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}
