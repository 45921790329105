import { CarOutlined, InboxOutlined, ShopOutlined } from "@ant-design/icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";

const NavMenu = () => {
  const navigate = useNavigate();

  const items = [
    {
      key: "MS",
      label: "Мой Склад",
      children: [
        {
          key: "Supply",
          label: "Отгрузки и приемки",
          onClick: () => navigate("/ms/demand-supply"),
        },
        {
          key: "Export",
          label: "Экспорт заказов",
          onClick: () => navigate("/ms/export-order"),
        },
      ],
      icon: <InboxOutlined rev={"icon"} />,
    },
    {
      key: "Shops",
      label: "Магазины",
      icon: <ShopOutlined rev={"icon"} />,
      onClick: () => navigate("/shops"),
    },
    {
      key: "Apiship",
      label: "Apiship",
      icon: <CarOutlined rev={"icon"} />,
      onClick: () => navigate("shipping"),
    },
  ];

  return <Menu items={items} mode={"inline"} />;
};

export default NavMenu;
