import { createSlice } from "@reduxjs/toolkit";
export interface CmsState {
}

const initialState: CmsState = {

}

export const msSlice = createSlice({
    name: 'ms',
    initialState,
    reducers: {

    }
})

export const { } = msSlice.actions

export default msSlice.reducer