import React, { useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Space,
  Typography,
} from "antd";
import {
  BuhN2ControllerApiFactory,
  Generate2RequestBody,
  Generate3RequestBody,
} from "../../generated/backend";
import moment from "moment";
import dayjs from "dayjs";

const DemandSupply = () => {
  const [formSupply] = Form.useForm();
  const [formDemand] = Form.useForm();
  const [supplyUpdating, setSupplyUpdating] = useState(false);
  const [demandUpdating, setDemandUpdating] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const buhApi = BuhN2ControllerApiFactory();

  const formSupplyFinish = async (values) => {
    const reqBody: Generate2RequestBody = {
      demandName1: parseInt(values.demandName1),
      demandName2: parseInt(values.demandName2) || parseInt(values.demandName1),
    };
    messageApi.open({
      type: "loading",
      content: "Создаю приемку...",
      duration: 0,
    });
    setSupplyUpdating(true);
    buhApi
      .generate2(reqBody)
      .then((res) => {
        messageApi.destroy();
        messageApi.success("Создано!", 2);
      })
      .catch((err) => {
        messageApi.destroy();
        messageApi.warning(`Что-то пошло не так: ${err.message}`, 5);
      })
      .finally(() => {
        setSupplyUpdating(false);
      });
  };

  const formDemandFinish = async (values) => {
    const reqBody: Generate3RequestBody = {
      date1: moment.utc(values.date1["$d"]).format("YYYYMMDD"),
      date2: moment.utc(values.date2["$d"]).format("YYYYMMDD"),
      restrictProjectId: values.restrictProjectId,
    };
    setDemandUpdating(true);
    messageApi.open({
      type: "loading",
      content: "Создаю отгрузки...",
      duration: 0,
    });
    buhApi
      .generate3(reqBody)
      .then((res) => {
        messageApi.destroy();
        messageApi.success("Создано!", 2);
      })
      .catch((err) => {
        messageApi.destroy();
        messageApi.warning(`Что-то пошло не так: ${err.message}`, 5);
      })
      .finally(() => {
        setDemandUpdating(false);
      });
  };

  return (
    <div className={"p-5"}>
      {contextHolder}
      <Form
        form={formSupply}
        layout={"vertical"}
        onFinish={formSupplyFinish}
        disabled={supplyUpdating}
      >
        <Typography.Title level={4}>Приёмки</Typography.Title>
        <Space>
          <Form.Item label={"Приемка 1"} name={"demandName1"}>
            <Input />
          </Form.Item>
          <Form.Item label={"Приемка 2"} name={"demandName2"}>
            <Input />
          </Form.Item>
        </Space>
        <Form.Item>
          <Button htmlType={"submit"}>Создать</Button>
        </Form.Item>
      </Form>

      <Form
        form={formDemand}
        layout={"vertical"}
        onFinish={formDemandFinish}
        initialValues={{
          date1: dayjs(new Date()),
          date2: dayjs(new Date()),
        }}
        disabled={demandUpdating}
      >
        <Typography.Title level={4}>Отгрузки</Typography.Title>
        <Space>
          <Form.Item label={"Дата 1"} name={"date1"}>
            <DatePicker mode={"date"} />
          </Form.Item>
          <Form.Item label={"Дата 2"} name={"date2"}>
            <DatePicker mode={"date"} />
          </Form.Item>
        </Space>
        <Form.Item
          label={"Ограничение проекта (ProjectId)"}
          name={"projectId"}
          wrapperCol={{ span: 4 }}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button htmlType={"submit"}>Создать</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default DemandSupply;
