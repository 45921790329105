import React, { useState } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Layout } from "antd";
import NavMenu from "./NavMenu";
import classNames from "classnames";
import styles from "./styles.module.css";

const LayoutSider = () => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout.Sider
      collapsed={collapsed}
      collapsible={true}
      onCollapse={(collapsed) => setCollapsed(collapsed)}
      theme={"light"}
      trigger={null}
      breakpoint={"lg"}
    >
      <NavMenu />
      <div
        className={classNames(styles.SiderButton)}
        onClick={() => setCollapsed(!collapsed)}
      >
        {collapsed ? (
          <MenuUnfoldOutlined rev={""} />
        ) : (
          <MenuFoldOutlined rev={""} />
        )}
      </div>
    </Layout.Sider>
  );
};

export default LayoutSider;
