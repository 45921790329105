import React from "react";
import { Input } from "antd";
import {
  FieldErrorDTO,
  FieldErrorDTOTypeEnum,
  FieldStringDTO,
} from "../../../generated/backend";

export interface InputProps {
  onChange: (value: string, errors: FieldErrorDTO[]) => void;
  value: FieldStringDTO;
}

const ShopFormInput = (props: InputProps) => {
  const { value, onChange } = props;

  const inputChange = (value) => {
    if (value.length > 0) {
      onChange(value, []);
    } else {
      onChange(value, [
        { type: FieldErrorDTOTypeEnum.Empty, message: "Не должно быть пустым" },
      ]);
    }
  };

  return (
    <Input onChange={(e) => inputChange(e.target.value)} value={value?.value} />
  );
};

export default ShopFormInput;
