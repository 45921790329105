import React, { useState } from "react";
import { Select } from "antd";
import {
  FieldSelectorDTO,
  FieldSelectorOptionDTO,
} from "../../../generated/backend";
import { AxiosPromise } from "axios";
import { InboxOutlined } from "@ant-design/icons";

export interface SelectProps {
  value: FieldSelectorDTO;
  getOptions: () => AxiosPromise<FieldSelectorOptionDTO[]>;
  onChange: (value) => void;
}

const ShopFormSelect = (props: SelectProps) => {
  const [options, setOptions] = useState<FieldSelectorOptionDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const { value, onChange } = props;

  const [messageContent, setMessageContent] = useState<
    string | React.ReactElement
  >(<p>Ничего не найдено</p>);

  const getOptions = async () => {
    setLoading(true);
    setMessageContent(<p>Загрузка...</p>);
    try {
      const res = await props.getOptions();
      setOptions(res.data);
    } catch (err) {
      setMessageContent(
        <>
          <p>Не удалось получить данные.</p>
          <p>Детали: {err.message}</p>
        </>
      );
    }
    setMessageContent(<p>Ничего не найдено</p>);
    setLoading(false);
  };

  const onSelect = (_, value) => {
    onChange({ value: value.value, label: value.label, errors: [] });
  };

  return (
    <>
      <Select
        value={value}
        onSelect={onSelect}
        showSearch={true}
        filterOption={(input, option) =>
          (option?.label.toString().toLowerCase() ?? "").includes(
            input.toLowerCase()
          )
        }
        loading={loading}
        options={options}
        allowClear={value.value !== null}
        onClear={() => onChange({ value: null, label: "", errors: [] })}
        notFoundContent={
          <div
            className={
              "flex items-center flex-col w-full p-2 text-sm text-gray-500"
            }
          >
            <InboxOutlined
              rev={""}
              style={{ fontSize: "32px", color: "lavender" }}
            />
            {messageContent}
          </div>
        }
        onClick={getOptions}
      />
    </>
  );
};

export default ShopFormSelect;
