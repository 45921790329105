import React, { useEffect, useState } from "react";
import { Button, Checkbox, Select, Space, Typography } from "antd";
import {
  InitControllerApiFactory,
  PaymentsControllerApiFactory,
  PointsControllerApiFactory,
  RoutesControllerApiFactory,
  ShopPlugin,
  UnishipStock,
  UnishipTransportCompany,
} from "../../generated/backend";
import { buttonClass } from "../styleHelpers";

const Apiship = () => {
  const pointsApi = PointsControllerApiFactory();
  const routesApi = RoutesControllerApiFactory();
  const paymentsApi = PaymentsControllerApiFactory();
  const api = InitControllerApiFactory();

  const [companyId, setCompanyId] = useState<string>();
  const [companyList, setCompanyList] = useState<UnishipTransportCompany[]>([]);

  const [stockId, setStockId] = useState<number>();
  const [stockList, setStockList] = useState<UnishipStock[]>([]);

  const [paymentIds, setPaymentIds] = useState<number[]>([]);
  const [paymentsList, setPaymentsList] = useState<ShopPlugin[]>([]);
  const [routesInsert, setRoutesInsert] = useState(false);
  const [routesUpdate, setRoutesUpdate] = useState(false);
  const [routesDummy, setRoutesDummy] = useState(false);

  const callPoints = async () => {
    try {
      await pointsApi.syncPoints({ companyId });
    } catch (err) {
      console.log(err);
    }
  };

  const callRoutes = async () => {
    try {
      await routesApi.setRoutes({
        companyId,
        routesInsert,
        routesUpdate,
        routesDummy,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const callRoutesPayments = async () => {
    try {
      await paymentsApi.setRoutesPayments({
        companyId,
        stockId,
        paymentIds,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const company = await api.showCompany();
        const payments = await api.showPayments();
        const stocks = await api.showStocks();

        setPaymentsList(payments.data);
        setCompanyList(company.data);
        setStockList(stocks.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <Space direction={"vertical"} className={"p-5 w-full"}>
      <Space direction={"vertical"} className={"pb-5 border-b w-full"}>
        <label className={"font-bold"}>Company</label>
        <Select onSelect={setCompanyId} value={companyId} className={"w-80"}>
          {companyList.map((company, i) => (
            <Select.Option key={i} value={company.id}>
              {company.name}
            </Select.Option>
          ))}
        </Select>
        <Typography.Text>Points</Typography.Text>
        <Button className={buttonClass()} onClick={callPoints}>
          Run
        </Button>
      </Space>

      <Space direction={"vertical"} className={"py-5 border-b w-full"}>
        <label className={"font-bold"}>Stock</label>
        <Select onSelect={setStockId} value={stockId} className={"w-80"}>
          {stockList.map((stock, i) => (
            <Select.Option key={i} value={stock.id}>
              {stock.name}
            </Select.Option>
          ))}
        </Select>
        <Checkbox.Group>
          <Checkbox
            name={"insert"}
            value={"insert"}
            checked={routesInsert}
            onChange={(e) => setRoutesInsert(e.target.checked)}
          >
            Insert
          </Checkbox>
          <Checkbox
            name={"update"}
            value={"update"}
            checked={routesUpdate}
            onChange={(e) => setRoutesUpdate(e.target.checked)}
          >
            Update
          </Checkbox>
          <Checkbox
            name={"dummy"}
            value={"dummy"}
            checked={routesDummy}
            onChange={(e) => setRoutesDummy(e.target.checked)}
          >
            Dummy
          </Checkbox>
        </Checkbox.Group>
        <Typography.Text>Routes</Typography.Text>
        <Button className={buttonClass()} onClick={callRoutes}>
          Run
        </Button>
      </Space>

      <Space direction={"vertical"} className={"pt-5 w-full"}>
        <Typography.Text className={"font-bold"}>Payments</Typography.Text>
        <Checkbox.Group
          onChange={(checkedValue: number[]) => {
            setPaymentIds(checkedValue);
          }}
          className={"flex flex-col"}
        >
          {paymentsList.map((payment) => {
            return (
              <Checkbox
                key={payment.id}
                value={payment.id}
                className={!!payment.status ? "" : "text-gray-300"}
              >
                {payment.id}: {payment.backendName}
              </Checkbox>
            );
          })}
        </Checkbox.Group>
        <Typography.Text>Payments</Typography.Text>
        <Button className={buttonClass()} onClick={callRoutesPayments}>
          Run
        </Button>
      </Space>
    </Space>
  );
};

export default Apiship;
