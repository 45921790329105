import React from "react";
import { Avatar, Button, Dropdown, Layout, Space } from "antd";
import classNames from "classnames";
import styles from "./styles.module.css";
import { LogoutOutlined, MenuOutlined } from "@ant-design/icons";
import { logout } from "../../store/slice/generalSlice";
import { useAppDispatch } from "../../store/store";

interface Props {
  showButton: boolean;
  buttonClick: () => void;
}

const Header = (props: Props) => {
  const { showButton, buttonClick } = props;
  const dispatch = useAppDispatch();

  const dropMenu = [
    {
      label: "Выход",
      key: "logout",
      icon: <LogoutOutlined rev={"icon"} />,
      onClick: () => {
        dispatch(logout());
      },
    },
  ];

  const dropProps = {
    items: dropMenu,
  };

  return (
    <Layout.Header className={classNames(styles.Header)}>
      <Space align={"center"}>
        <img
          className="h-8 w-auto"
          src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-900-text.svg"
          alt="Workflow"
        />
        {showButton && (
          <Button
            type={"link"}
            icon={<MenuOutlined rev={""} />}
            size={"large"}
            onClick={buttonClick}
          />
        )}
      </Space>

      <Dropdown menu={dropProps}>
        <Avatar>user</Avatar>
      </Dropdown>
    </Layout.Header>
  );
};

export default Header;
