import { createSlice, Slice } from "@reduxjs/toolkit";
import { MsShopListItemDTO } from "../../generated/backend";

interface InitialState {
  selectedShop: MsShopListItemDTO | null;
}

const initialState = { selectedShop: null } as InitialState;

const ShopSlice = createSlice({
  name: "Shops",
  initialState,
  reducers: {
    setSelectedShop: (state, action) => {
      state.selectedShop = action.payload;
    },
  },
});

export const { setSelectedShop } = ShopSlice.actions;

export default ShopSlice.reducer;
