import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "antd";
import { Outlet, useParams } from "react-router-dom";
import ShopList from "./ShopList";

const ShopLayout = () => {
  const [selectedShopID, setSelectedShopID] = useState(null);
  const params = useParams();
  const column = useRef(null);

  useEffect(() => {
    if (params.id) {
      setSelectedShopID(Number(params.id));
    }
  }, [params.id]);

  return (
    <Row className={"h-full"}>
      <Col
        className={"h-full overflow-y-scroll bg-white"}
        ref={column}
        span={6}
      >
        <ShopList selectedID={selectedShopID} container={column} />
      </Col>
      <Col span={18} className={"bg-gray-50"}>
        <Outlet />
      </Col>
    </Row>
  );
};

export default ShopLayout;
