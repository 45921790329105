/* tslint:disable */
/* eslint-disable */
/**
 * 
 * 
 *
 * 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AuditContext
 */
export interface AuditContext {
    /**
     * 
     * @type {string}
     * @memberof AuditContext
     */
    'moment': string;
}
/**
 * 
 * @export
 * @interface AuthResponse
 */
export interface AuthResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthResponse
     */
    'access_token': string;
}
/**
 * 
 * @export
 * @interface Events
 */
export interface Events {
    /**
     * 
     * @type {Meta}
     * @memberof Events
     */
    'meta': Meta;
    /**
     * 
     * @type {Array<string>}
     * @memberof Events
     */
    'updatedFields'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Events
     */
    'action': string;
    /**
     * 
     * @type {string}
     * @memberof Events
     */
    'accountId': string;
}
/**
 * 
 * @export
 * @interface FieldErrorDTO
 */
export interface FieldErrorDTO {
    /**
     * 
     * @type {string}
     * @memberof FieldErrorDTO
     */
    'type': FieldErrorDTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FieldErrorDTO
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof FieldErrorDTO
     */
    'details'?: string;
}

export const FieldErrorDTOTypeEnum = {
    Empty: 'Empty',
    NotFound: 'NotFound',
    NonUnique: 'NonUnique',
    Generic: 'Generic'
} as const;

export type FieldErrorDTOTypeEnum = typeof FieldErrorDTOTypeEnum[keyof typeof FieldErrorDTOTypeEnum];

/**
 * 
 * @export
 * @interface FieldSelectorDTO
 */
export interface FieldSelectorDTO {
    /**
     * 
     * @type {string}
     * @memberof FieldSelectorDTO
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof FieldSelectorDTO
     */
    'label'?: string;
    /**
     * 
     * @type {Array<FieldErrorDTO>}
     * @memberof FieldSelectorDTO
     */
    'errors': Array<FieldErrorDTO>;
}
/**
 * 
 * @export
 * @interface FieldSelectorOptionDTO
 */
export interface FieldSelectorOptionDTO {
    /**
     * 
     * @type {string}
     * @memberof FieldSelectorOptionDTO
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof FieldSelectorOptionDTO
     */
    'label': string;
}
/**
 * 
 * @export
 * @interface FieldStringDTO
 */
export interface FieldStringDTO {
    /**
     * 
     * @type {string}
     * @memberof FieldStringDTO
     */
    'value': string;
    /**
     * 
     * @type {Array<FieldErrorDTO>}
     * @memberof FieldStringDTO
     */
    'errors': Array<FieldErrorDTO>;
}
/**
 * 
 * @export
 * @interface FillSkusMsIdRequestBody
 */
export interface FillSkusMsIdRequestBody {
    /**
     * 
     * @type {Array<number>}
     * @memberof FillSkusMsIdRequestBody
     */
    'skuIds'?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FillSkusMsIdRequestBody
     */
    'msAccounts': Array<FillSkusMsIdRequestBodyMsAccountsEnum>;
}

export const FillSkusMsIdRequestBodyMsAccountsEnum = {
    Main: 'MAIN',
    Retail: 'RETAIL'
} as const;

export type FillSkusMsIdRequestBodyMsAccountsEnum = typeof FillSkusMsIdRequestBodyMsAccountsEnum[keyof typeof FillSkusMsIdRequestBodyMsAccountsEnum];

/**
 * 
 * @export
 * @interface Generate2RequestBody
 */
export interface Generate2RequestBody {
    /**
     * 
     * @type {number}
     * @memberof Generate2RequestBody
     */
    'demandName1': number;
    /**
     * 
     * @type {number}
     * @memberof Generate2RequestBody
     */
    'demandName2'?: number;
}
/**
 * 
 * @export
 * @interface Generate3RequestBody
 */
export interface Generate3RequestBody {
    /**
     * 
     * @type {string}
     * @memberof Generate3RequestBody
     */
    'date1': string;
    /**
     * 
     * @type {string}
     * @memberof Generate3RequestBody
     */
    'date2': string;
    /**
     * 
     * @type {string}
     * @memberof Generate3RequestBody
     */
    'restrictProjectId'?: string;
}
/**
 * 
 * @export
 * @interface GetHealth200ResponseInner
 */
export interface GetHealth200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof GetHealth200ResponseInner
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetHealth200ResponseInner
     */
    'priority'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetHealth200ResponseInner
     */
    'daemon'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetHealth200ResponseInner
     */
    'interrupted'?: boolean;
    /**
     * 
     * @type {GetHealth200ResponseInnerContextClassLoader}
     * @memberof GetHealth200ResponseInner
     */
    'contextClassLoader'?: GetHealth200ResponseInnerContextClassLoader;
    /**
     * 
     * @type {object}
     * @memberof GetHealth200ResponseInner
     */
    'uncaughtExceptionHandler'?: object;
    /**
     * 
     * @type {GetHealth200ResponseInnerThreadGroup}
     * @memberof GetHealth200ResponseInner
     */
    'threadGroup'?: GetHealth200ResponseInnerThreadGroup;
    /**
     * 
     * @type {Array<GetHealth200ResponseInnerStackTraceInner>}
     * @memberof GetHealth200ResponseInner
     */
    'stackTrace'?: Array<GetHealth200ResponseInnerStackTraceInner>;
    /**
     * 
     * @type {boolean}
     * @memberof GetHealth200ResponseInner
     */
    'alive'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetHealth200ResponseInner
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetHealth200ResponseInner
     */
    'state'?: GetHealth200ResponseInnerStateEnum;
}

export const GetHealth200ResponseInnerStateEnum = {
    New: 'NEW',
    Runnable: 'RUNNABLE',
    Blocked: 'BLOCKED',
    Waiting: 'WAITING',
    TimedWaiting: 'TIMED_WAITING',
    Terminated: 'TERMINATED'
} as const;

export type GetHealth200ResponseInnerStateEnum = typeof GetHealth200ResponseInnerStateEnum[keyof typeof GetHealth200ResponseInnerStateEnum];

/**
 * 
 * @export
 * @interface GetHealth200ResponseInnerContextClassLoader
 */
export interface GetHealth200ResponseInnerContextClassLoader {
    /**
     * 
     * @type {string}
     * @memberof GetHealth200ResponseInnerContextClassLoader
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetHealth200ResponseInnerContextClassLoader
     */
    'registeredAsParallelCapable'?: boolean;
    /**
     * 
     * @type {GetHealth200ResponseInnerContextClassLoaderUnnamedModule}
     * @memberof GetHealth200ResponseInnerContextClassLoader
     */
    'unnamedModule'?: GetHealth200ResponseInnerContextClassLoaderUnnamedModule;
    /**
     * 
     * @type {Array<GetHealth200ResponseInnerContextClassLoaderUnnamedModuleClassLoaderDefinedPackagesInner>}
     * @memberof GetHealth200ResponseInnerContextClassLoader
     */
    'definedPackages'?: Array<GetHealth200ResponseInnerContextClassLoaderUnnamedModuleClassLoaderDefinedPackagesInner>;
    /**
     * 
     * @type {boolean}
     * @memberof GetHealth200ResponseInnerContextClassLoader
     */
    'defaultAssertionStatus'?: boolean;
}
/**
 * 
 * @export
 * @interface GetHealth200ResponseInnerContextClassLoaderUnnamedModule
 */
export interface GetHealth200ResponseInnerContextClassLoaderUnnamedModule {
    /**
     * 
     * @type {string}
     * @memberof GetHealth200ResponseInnerContextClassLoaderUnnamedModule
     */
    'name'?: string;
    /**
     * 
     * @type {GetHealth200ResponseInnerContextClassLoaderUnnamedModuleClassLoader}
     * @memberof GetHealth200ResponseInnerContextClassLoaderUnnamedModule
     */
    'classLoader'?: GetHealth200ResponseInnerContextClassLoaderUnnamedModuleClassLoader;
    /**
     * 
     * @type {GetHealth200ResponseInnerContextClassLoaderUnnamedModuleDescriptor}
     * @memberof GetHealth200ResponseInnerContextClassLoaderUnnamedModule
     */
    'descriptor'?: GetHealth200ResponseInnerContextClassLoaderUnnamedModuleDescriptor;
    /**
     * 
     * @type {boolean}
     * @memberof GetHealth200ResponseInnerContextClassLoaderUnnamedModule
     */
    'named'?: boolean;
    /**
     * 
     * @type {Array<object>}
     * @memberof GetHealth200ResponseInnerContextClassLoaderUnnamedModule
     */
    'annotations'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof GetHealth200ResponseInnerContextClassLoaderUnnamedModule
     */
    'declaredAnnotations'?: Array<object>;
    /**
     * 
     * @type {Set<string>}
     * @memberof GetHealth200ResponseInnerContextClassLoaderUnnamedModule
     */
    'packages'?: Set<string>;
    /**
     * 
     * @type {object}
     * @memberof GetHealth200ResponseInnerContextClassLoaderUnnamedModule
     */
    'layer'?: object;
}
/**
 * 
 * @export
 * @interface GetHealth200ResponseInnerContextClassLoaderUnnamedModuleClassLoader
 */
export interface GetHealth200ResponseInnerContextClassLoaderUnnamedModuleClassLoader {
    /**
     * 
     * @type {string}
     * @memberof GetHealth200ResponseInnerContextClassLoaderUnnamedModuleClassLoader
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetHealth200ResponseInnerContextClassLoaderUnnamedModuleClassLoader
     */
    'registeredAsParallelCapable'?: boolean;
    /**
     * 
     * @type {Array<GetHealth200ResponseInnerContextClassLoaderUnnamedModuleClassLoaderDefinedPackagesInner>}
     * @memberof GetHealth200ResponseInnerContextClassLoaderUnnamedModuleClassLoader
     */
    'definedPackages'?: Array<GetHealth200ResponseInnerContextClassLoaderUnnamedModuleClassLoaderDefinedPackagesInner>;
    /**
     * 
     * @type {boolean}
     * @memberof GetHealth200ResponseInnerContextClassLoaderUnnamedModuleClassLoader
     */
    'defaultAssertionStatus'?: boolean;
}
/**
 * 
 * @export
 * @interface GetHealth200ResponseInnerContextClassLoaderUnnamedModuleClassLoaderDefinedPackagesInner
 */
export interface GetHealth200ResponseInnerContextClassLoaderUnnamedModuleClassLoaderDefinedPackagesInner {
    /**
     * 
     * @type {string}
     * @memberof GetHealth200ResponseInnerContextClassLoaderUnnamedModuleClassLoaderDefinedPackagesInner
     */
    'name'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof GetHealth200ResponseInnerContextClassLoaderUnnamedModuleClassLoaderDefinedPackagesInner
     */
    'annotations'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof GetHealth200ResponseInnerContextClassLoaderUnnamedModuleClassLoaderDefinedPackagesInner
     */
    'declaredAnnotations'?: Array<object>;
    /**
     * 
     * @type {boolean}
     * @memberof GetHealth200ResponseInnerContextClassLoaderUnnamedModuleClassLoaderDefinedPackagesInner
     */
    'sealed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetHealth200ResponseInnerContextClassLoaderUnnamedModuleClassLoaderDefinedPackagesInner
     */
    'specificationTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHealth200ResponseInnerContextClassLoaderUnnamedModuleClassLoaderDefinedPackagesInner
     */
    'specificationVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHealth200ResponseInnerContextClassLoaderUnnamedModuleClassLoaderDefinedPackagesInner
     */
    'specificationVendor'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHealth200ResponseInnerContextClassLoaderUnnamedModuleClassLoaderDefinedPackagesInner
     */
    'implementationTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHealth200ResponseInnerContextClassLoaderUnnamedModuleClassLoaderDefinedPackagesInner
     */
    'implementationVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHealth200ResponseInnerContextClassLoaderUnnamedModuleClassLoaderDefinedPackagesInner
     */
    'implementationVendor'?: string;
}
/**
 * 
 * @export
 * @interface GetHealth200ResponseInnerContextClassLoaderUnnamedModuleDescriptor
 */
export interface GetHealth200ResponseInnerContextClassLoaderUnnamedModuleDescriptor {
    /**
     * 
     * @type {boolean}
     * @memberof GetHealth200ResponseInnerContextClassLoaderUnnamedModuleDescriptor
     */
    'open'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetHealth200ResponseInnerContextClassLoaderUnnamedModuleDescriptor
     */
    'automatic'?: boolean;
}
/**
 * 
 * @export
 * @interface GetHealth200ResponseInnerStackTraceInner
 */
export interface GetHealth200ResponseInnerStackTraceInner {
    /**
     * 
     * @type {string}
     * @memberof GetHealth200ResponseInnerStackTraceInner
     */
    'classLoaderName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHealth200ResponseInnerStackTraceInner
     */
    'moduleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHealth200ResponseInnerStackTraceInner
     */
    'moduleVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHealth200ResponseInnerStackTraceInner
     */
    'methodName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHealth200ResponseInnerStackTraceInner
     */
    'fileName'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetHealth200ResponseInnerStackTraceInner
     */
    'lineNumber'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetHealth200ResponseInnerStackTraceInner
     */
    'nativeMethod'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetHealth200ResponseInnerStackTraceInner
     */
    'className'?: string;
}
/**
 * 
 * @export
 * @interface GetHealth200ResponseInnerThreadGroup
 */
export interface GetHealth200ResponseInnerThreadGroup {
    /**
     * 
     * @type {string}
     * @memberof GetHealth200ResponseInnerThreadGroup
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetHealth200ResponseInnerThreadGroup
     */
    'maxPriority'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetHealth200ResponseInnerThreadGroup
     * @deprecated
     */
    'destroyed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetHealth200ResponseInnerThreadGroup
     * @deprecated
     */
    'daemon'?: boolean;
}
/**
 * 
 * @export
 * @interface HookRequest
 */
export interface HookRequest {
    /**
     * 
     * @type {AuditContext}
     * @memberof HookRequest
     */
    'auditContext'?: AuditContext;
    /**
     * 
     * @type {Array<Events>}
     * @memberof HookRequest
     */
    'events': Array<Events>;
}
/**
 * 
 * @export
 * @interface ImportProductsRequestBody
 */
export interface ImportProductsRequestBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportProductsRequestBody
     */
    'msAccounts': Array<ImportProductsRequestBodyMsAccountsEnum>;
}

export const ImportProductsRequestBodyMsAccountsEnum = {
    Main: 'MAIN',
    Retail: 'RETAIL'
} as const;

export type ImportProductsRequestBodyMsAccountsEnum = typeof ImportProductsRequestBodyMsAccountsEnum[keyof typeof ImportProductsRequestBodyMsAccountsEnum];

/**
 * 
 * @export
 * @interface Meta
 */
export interface Meta {
    /**
     * 
     * @type {string}
     * @memberof Meta
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof Meta
     */
    'href': string;
}
/**
 * 
 * @export
 * @interface MsOrderExportRequest
 */
export interface MsOrderExportRequest {
    /**
     * 
     * @type {number}
     * @memberof MsOrderExportRequest
     */
    'orderId': number;
    /**
     * 
     * @type {number}
     * @memberof MsOrderExportRequest
     */
    'msAccountIndex': number;
}
/**
 * 
 * @export
 * @interface MsShopFormDTO
 */
export interface MsShopFormDTO {
    /**
     * 
     * @type {number}
     * @memberof MsShopFormDTO
     */
    'id': number;
    /**
     * 
     * @type {FieldStringDTO}
     * @memberof MsShopFormDTO
     */
    'name': FieldStringDTO;
    /**
     * 
     * @type {FieldSelectorDTO}
     * @memberof MsShopFormDTO
     */
    'msOrganization': FieldSelectorDTO;
    /**
     * 
     * @type {FieldSelectorDTO}
     * @memberof MsShopFormDTO
     */
    'msStore': FieldSelectorDTO;
    /**
     * 
     * @type {FieldSelectorDTO}
     * @memberof MsShopFormDTO
     */
    'msGroup': FieldSelectorDTO;
    /**
     * 
     * @type {FieldSelectorDTO}
     * @memberof MsShopFormDTO
     */
    'msMainContact': FieldSelectorDTO;
    /**
     * 
     * @type {FieldSelectorDTO}
     * @memberof MsShopFormDTO
     */
    'msProject': FieldSelectorDTO;
    /**
     * 
     * @type {FieldSelectorDTO}
     * @memberof MsShopFormDTO
     */
    'msMainProject': FieldSelectorDTO;
    /**
     * 
     * @type {string}
     * @memberof MsShopFormDTO
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface MsShopFormSaveDTO
 */
export interface MsShopFormSaveDTO {
    /**
     * 
     * @type {number}
     * @memberof MsShopFormSaveDTO
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MsShopFormSaveDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MsShopFormSaveDTO
     */
    'msOrganizationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MsShopFormSaveDTO
     */
    'msStoreId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MsShopFormSaveDTO
     */
    'msGroupId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MsShopFormSaveDTO
     */
    'msMainContactId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MsShopFormSaveDTO
     */
    'msProjectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MsShopFormSaveDTO
     */
    'msMainProjectId'?: string;
}
/**
 * 
 * @export
 * @interface MsShopListItemDTO
 */
export interface MsShopListItemDTO {
    /**
     * 
     * @type {number}
     * @memberof MsShopListItemDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MsShopListItemDTO
     */
    'shippingName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MsShopListItemDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MsShopListItemDTO
     */
    'msOrganizationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MsShopListItemDTO
     */
    'msStoreId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MsShopListItemDTO
     */
    'msGroupId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MsShopListItemDTO
     */
    'msMainContactId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MsShopListItemDTO
     */
    'msProjectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MsShopListItemDTO
     */
    'msMainProjectId'?: string;
}
/**
 * 
 * @export
 * @interface PhpCommonResponseBody
 */
export interface PhpCommonResponseBody {
    /**
     * 
     * @type {Array<ReportMessage>}
     * @memberof PhpCommonResponseBody
     */
    'messages': Array<ReportMessage>;
}
/**
 * 
 * @export
 * @interface PhpMsCreateProductRequestBody
 */
export interface PhpMsCreateProductRequestBody {
    /**
     * 
     * @type {number}
     * @memberof PhpMsCreateProductRequestBody
     */
    'productId': number;
    /**
     * 
     * @type {string}
     * @memberof PhpMsCreateProductRequestBody
     */
    'testPrefix': string;
}
/**
 * 
 * @export
 * @interface PointsControllerRequest
 */
export interface PointsControllerRequest {
    /**
     * 
     * @type {string}
     * @memberof PointsControllerRequest
     */
    'companyId': string;
}
/**
 * 
 * @export
 * @interface ReportMessage
 */
export interface ReportMessage {
    /**
     * 
     * @type {string}
     * @memberof ReportMessage
     */
    'type': ReportMessageTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportMessage
     */
    'text': string;
}

export const ReportMessageTypeEnum = {
    Error: 'ERROR',
    Info: 'INFO'
} as const;

export type ReportMessageTypeEnum = typeof ReportMessageTypeEnum[keyof typeof ReportMessageTypeEnum];

/**
 * 
 * @export
 * @interface RoutesControllerRequest
 */
export interface RoutesControllerRequest {
    /**
     * 
     * @type {string}
     * @memberof RoutesControllerRequest
     */
    'companyId': string;
    /**
     * 
     * @type {boolean}
     * @memberof RoutesControllerRequest
     */
    'routesInsert': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RoutesControllerRequest
     */
    'routesUpdate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RoutesControllerRequest
     */
    'routesDummy': boolean;
}
/**
 * 
 * @export
 * @interface RoutesPaymentsRequest
 */
export interface RoutesPaymentsRequest {
    /**
     * 
     * @type {string}
     * @memberof RoutesPaymentsRequest
     */
    'companyId': string;
    /**
     * 
     * @type {number}
     * @memberof RoutesPaymentsRequest
     */
    'stockId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof RoutesPaymentsRequest
     */
    'paymentIds': Array<number>;
}
/**
 * 
 * @export
 * @interface ShopPlugin
 */
export interface ShopPlugin {
    /**
     * 
     * @type {number}
     * @memberof ShopPlugin
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShopPlugin
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ShopPlugin
     */
    'plugin': string;
    /**
     * 
     * @type {string}
     * @memberof ShopPlugin
     */
    'backendName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShopPlugin
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ShopPlugin
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ShopPlugin
     */
    'logo': string;
    /**
     * 
     * @type {number}
     * @memberof ShopPlugin
     */
    'status': number;
    /**
     * 
     * @type {number}
     * @memberof ShopPlugin
     */
    'sort': number;
}
/**
 * 
 * @export
 * @interface UnishipStock
 */
export interface UnishipStock {
    /**
     * 
     * @type {number}
     * @memberof UnishipStock
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UnishipStock
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UnishipTransportCompany
 */
export interface UnishipTransportCompany {
    /**
     * 
     * @type {string}
     * @memberof UnishipTransportCompany
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UnishipTransportCompany
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UnishipTransportCompany
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UserAuth
 */
export interface UserAuth {
    /**
     * 
     * @type {string}
     * @memberof UserAuth
     */
    'userLogin': string;
    /**
     * 
     * @type {string}
     * @memberof UserAuth
     */
    'userPassword': string;
}

/**
 * AdminControllerApi - axios parameter creator
 * @export
 */
export const AdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FillSkusMsIdRequestBody} fillSkusMsIdRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fillSkusMsId: async (fillSkusMsIdRequestBody: FillSkusMsIdRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fillSkusMsIdRequestBody' is not null or undefined
            assertParamExists('fillSkusMsId', 'fillSkusMsIdRequestBody', fillSkusMsIdRequestBody)
            const localVarPath = `/api-admin/fillSkusMsId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fillSkusMsIdRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-admin/threads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ImportProductsRequestBody} importProductsRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importProducts: async (importProductsRequestBody: ImportProductsRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'importProductsRequestBody' is not null or undefined
            assertParamExists('importProducts', 'importProductsRequestBody', importProductsRequestBody)
            const localVarPath = `/api-admin/importProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(importProductsRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminControllerApi - functional programming interface
 * @export
 */
export const AdminControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {FillSkusMsIdRequestBody} fillSkusMsIdRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fillSkusMsId(fillSkusMsIdRequestBody: FillSkusMsIdRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fillSkusMsId(fillSkusMsIdRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetHealth200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ImportProductsRequestBody} importProductsRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importProducts(importProductsRequestBody: ImportProductsRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importProducts(importProductsRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminControllerApi - factory interface
 * @export
 */
export const AdminControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {FillSkusMsIdRequestBody} fillSkusMsIdRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fillSkusMsId(fillSkusMsIdRequestBody: FillSkusMsIdRequestBody, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.fillSkusMsId(fillSkusMsIdRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth(options?: any): AxiosPromise<Array<GetHealth200ResponseInner>> {
            return localVarFp.getHealth(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ImportProductsRequestBody} importProductsRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importProducts(importProductsRequestBody: ImportProductsRequestBody, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.importProducts(importProductsRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminControllerApi - object-oriented interface
 * @export
 * @class AdminControllerApi
 * @extends {BaseAPI}
 */
export class AdminControllerApi extends BaseAPI {
    /**
     * 
     * @param {FillSkusMsIdRequestBody} fillSkusMsIdRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public fillSkusMsId(fillSkusMsIdRequestBody: FillSkusMsIdRequestBody, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).fillSkusMsId(fillSkusMsIdRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getHealth(options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).getHealth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImportProductsRequestBody} importProductsRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public importProducts(importProductsRequestBody: ImportProductsRequestBody, options?: AxiosRequestConfig) {
        return AdminControllerApiFp(this.configuration).importProducts(importProductsRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthControllerApi - axios parameter creator
 * @export
 */
export const AuthControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/getMe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userLogin 
         * @param {string} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserToken: async (userLogin: string, refreshToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userLogin' is not null or undefined
            assertParamExists('updateUserToken', 'userLogin', userLogin)
            // verify required parameter 'refreshToken' is not null or undefined
            assertParamExists('updateUserToken', 'refreshToken', refreshToken)
            const localVarPath = `/api/user/updateToken/{userLogin}`
                .replace(`{${"userLogin"}}`, encodeURIComponent(String(userLogin)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserAuth} userAuth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAuth: async (userAuth: UserAuth, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userAuth' is not null or undefined
            assertParamExists('userAuth', 'userAuth', userAuth)
            const localVarPath = `/api/user/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAuth, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthControllerApi - functional programming interface
 * @export
 */
export const AuthControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userLogin 
         * @param {string} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserToken(userLogin: string, refreshToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserToken(userLogin, refreshToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserAuth} userAuth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAuth(userAuth: UserAuth, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAuth(userAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthControllerApi - factory interface
 * @export
 */
export const AuthControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(options?: any): AxiosPromise<string> {
            return localVarFp.getMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userLogin 
         * @param {string} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserToken(userLogin: string, refreshToken: string, options?: any): AxiosPromise<string> {
            return localVarFp.updateUserToken(userLogin, refreshToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserAuth} userAuth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAuth(userAuth: UserAuth, options?: any): AxiosPromise<AuthResponse> {
            return localVarFp.userAuth(userAuth, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthControllerApi - object-oriented interface
 * @export
 * @class AuthControllerApi
 * @extends {BaseAPI}
 */
export class AuthControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public getMe(options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).getMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userLogin 
     * @param {string} refreshToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public updateUserToken(userLogin: string, refreshToken: string, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).updateUserToken(userLogin, refreshToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserAuth} userAuth 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public userAuth(userAuth: UserAuth, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).userAuth(userAuth, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BuhN2ControllerApi - axios parameter creator
 * @export
 */
export const BuhN2ControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Generate2RequestBody} generate2RequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generate2: async (generate2RequestBody: Generate2RequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generate2RequestBody' is not null or undefined
            assertParamExists('generate2', 'generate2RequestBody', generate2RequestBody)
            const localVarPath = `/api/ms/generate2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generate2RequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Generate3RequestBody} generate3RequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generate3: async (generate3RequestBody: Generate3RequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generate3RequestBody' is not null or undefined
            assertParamExists('generate3', 'generate3RequestBody', generate3RequestBody)
            const localVarPath = `/api/ms/generate3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generate3RequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BuhN2ControllerApi - functional programming interface
 * @export
 */
export const BuhN2ControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BuhN2ControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Generate2RequestBody} generate2RequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generate2(generate2RequestBody: Generate2RequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generate2(generate2RequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Generate3RequestBody} generate3RequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generate3(generate3RequestBody: Generate3RequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generate3(generate3RequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BuhN2ControllerApi - factory interface
 * @export
 */
export const BuhN2ControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BuhN2ControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {Generate2RequestBody} generate2RequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generate2(generate2RequestBody: Generate2RequestBody, options?: any): AxiosPromise<void> {
            return localVarFp.generate2(generate2RequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Generate3RequestBody} generate3RequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generate3(generate3RequestBody: Generate3RequestBody, options?: any): AxiosPromise<void> {
            return localVarFp.generate3(generate3RequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BuhN2ControllerApi - object-oriented interface
 * @export
 * @class BuhN2ControllerApi
 * @extends {BaseAPI}
 */
export class BuhN2ControllerApi extends BaseAPI {
    /**
     * 
     * @param {Generate2RequestBody} generate2RequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuhN2ControllerApi
     */
    public generate2(generate2RequestBody: Generate2RequestBody, options?: AxiosRequestConfig) {
        return BuhN2ControllerApiFp(this.configuration).generate2(generate2RequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Generate3RequestBody} generate3RequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuhN2ControllerApi
     */
    public generate3(generate3RequestBody: Generate3RequestBody, options?: AxiosRequestConfig) {
        return BuhN2ControllerApiFp(this.configuration).generate3(generate3RequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InitControllerApi - axios parameter creator
 * @export
 */
export const InitControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showCompany: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/apiship/init/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showPayments: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/apiship/init/payments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showStocks: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/apiship/init/stocks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InitControllerApi - functional programming interface
 * @export
 */
export const InitControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InitControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showCompany(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UnishipTransportCompany>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showCompany(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showPayments(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShopPlugin>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showPayments(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showStocks(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UnishipStock>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showStocks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InitControllerApi - factory interface
 * @export
 */
export const InitControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InitControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showCompany(options?: any): AxiosPromise<Array<UnishipTransportCompany>> {
            return localVarFp.showCompany(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showPayments(options?: any): AxiosPromise<Array<ShopPlugin>> {
            return localVarFp.showPayments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showStocks(options?: any): AxiosPromise<Array<UnishipStock>> {
            return localVarFp.showStocks(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InitControllerApi - object-oriented interface
 * @export
 * @class InitControllerApi
 * @extends {BaseAPI}
 */
export class InitControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InitControllerApi
     */
    public showCompany(options?: AxiosRequestConfig) {
        return InitControllerApiFp(this.configuration).showCompany(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InitControllerApi
     */
    public showPayments(options?: AxiosRequestConfig) {
        return InitControllerApiFp(this.configuration).showPayments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InitControllerApi
     */
    public showStocks(options?: AxiosRequestConfig) {
        return InitControllerApiFp(this.configuration).showStocks(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MsControllerApi - axios parameter creator
 * @export
 */
export const MsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {HookRequest} hookRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        msCreateProduct1: async (hookRequest: HookRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hookRequest' is not null or undefined
            assertParamExists('msCreateProduct1', 'hookRequest', hookRequest)
            const localVarPath = `/api-ms/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hookRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MsControllerApi - functional programming interface
 * @export
 */
export const MsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {HookRequest} hookRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async msCreateProduct1(hookRequest: HookRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.msCreateProduct1(hookRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MsControllerApi - factory interface
 * @export
 */
export const MsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {HookRequest} hookRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        msCreateProduct1(hookRequest: HookRequest, options?: any): AxiosPromise<string> {
            return localVarFp.msCreateProduct1(hookRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MsControllerApi - object-oriented interface
 * @export
 * @class MsControllerApi
 * @extends {BaseAPI}
 */
export class MsControllerApi extends BaseAPI {
    /**
     * 
     * @param {HookRequest} hookRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MsControllerApi
     */
    public msCreateProduct1(hookRequest: HookRequest, options?: AxiosRequestConfig) {
        return MsControllerApiFp(this.configuration).msCreateProduct1(hookRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MsHookControllerApi - axios parameter creator
 * @export
 */
export const MsHookControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [secret] 
         * @param {HookRequest} [hookRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderPost: async (secret?: string, hookRequest?: HookRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mshook/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (secret !== undefined) {
                localVarQueryParameter['secret'] = secret;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hookRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MsHookControllerApi - functional programming interface
 * @export
 */
export const MsHookControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MsHookControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [secret] 
         * @param {HookRequest} [hookRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrderPost(secret?: string, hookRequest?: HookRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderPost(secret, hookRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MsHookControllerApi - factory interface
 * @export
 */
export const MsHookControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MsHookControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [secret] 
         * @param {HookRequest} [hookRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderPost(secret?: string, hookRequest?: HookRequest, options?: any): AxiosPromise<string> {
            return localVarFp.updateOrderPost(secret, hookRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MsHookControllerApi - object-oriented interface
 * @export
 * @class MsHookControllerApi
 * @extends {BaseAPI}
 */
export class MsHookControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} [secret] 
     * @param {HookRequest} [hookRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MsHookControllerApi
     */
    public updateOrderPost(secret?: string, hookRequest?: HookRequest, options?: AxiosRequestConfig) {
        return MsHookControllerApiFp(this.configuration).updateOrderPost(secret, hookRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MsOrderControllerApi - axios parameter creator
 * @export
 */
export const MsOrderControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MsOrderExportRequest} msOrderExportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportOrder: async (msOrderExportRequest: MsOrderExportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'msOrderExportRequest' is not null or undefined
            assertParamExists('exportOrder', 'msOrderExportRequest', msOrderExportRequest)
            const localVarPath = `/api/ms/order/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(msOrderExportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MsOrderControllerApi - functional programming interface
 * @export
 */
export const MsOrderControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MsOrderControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MsOrderExportRequest} msOrderExportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportOrder(msOrderExportRequest: MsOrderExportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportOrder(msOrderExportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MsOrderControllerApi - factory interface
 * @export
 */
export const MsOrderControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MsOrderControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {MsOrderExportRequest} msOrderExportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportOrder(msOrderExportRequest: MsOrderExportRequest, options?: any): AxiosPromise<string> {
            return localVarFp.exportOrder(msOrderExportRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MsOrderControllerApi - object-oriented interface
 * @export
 * @class MsOrderControllerApi
 * @extends {BaseAPI}
 */
export class MsOrderControllerApi extends BaseAPI {
    /**
     * 
     * @param {MsOrderExportRequest} msOrderExportRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MsOrderControllerApi
     */
    public exportOrder(msOrderExportRequest: MsOrderExportRequest, options?: AxiosRequestConfig) {
        return MsOrderControllerApiFp(this.configuration).exportOrder(msOrderExportRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MsShopControllerApi - axios parameter creator
 * @export
 */
export const MsShopControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForm: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getForm', 'id', id)
            const localVarPath = `/api/shops/init/form`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shops/init/ms_groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shops/init/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMainContacts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shops/init/ms_main_contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMainProjects: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shops/init/ms_main_projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get organizations list from MS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shops/init/ms_organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shops/init/ms_projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStores: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shops/init/ms_stores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MsShopFormSaveDTO} msShopFormSaveDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (msShopFormSaveDTO: MsShopFormSaveDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'msShopFormSaveDTO' is not null or undefined
            assertParamExists('update', 'msShopFormSaveDTO', msShopFormSaveDTO)
            const localVarPath = `/api/shops/init/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(msShopFormSaveDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MsShopControllerApi - functional programming interface
 * @export
 */
export const MsShopControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MsShopControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getForm(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MsShopFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getForm(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FieldSelectorOptionDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MsShopListItemDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMainContacts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FieldSelectorOptionDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMainContacts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMainProjects(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FieldSelectorOptionDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMainProjects(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get organizations list from MS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FieldSelectorOptionDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjects(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FieldSelectorOptionDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjects(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStores(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FieldSelectorOptionDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStores(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MsShopFormSaveDTO} msShopFormSaveDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(msShopFormSaveDTO: MsShopFormSaveDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MsShopFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(msShopFormSaveDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MsShopControllerApi - factory interface
 * @export
 */
export const MsShopControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MsShopControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForm(id: number, options?: any): AxiosPromise<MsShopFormDTO> {
            return localVarFp.getForm(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroups(options?: any): AxiosPromise<Array<FieldSelectorOptionDTO>> {
            return localVarFp.getGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(options?: any): AxiosPromise<Array<MsShopListItemDTO>> {
            return localVarFp.getList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMainContacts(options?: any): AxiosPromise<Array<FieldSelectorOptionDTO>> {
            return localVarFp.getMainContacts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMainProjects(options?: any): AxiosPromise<Array<FieldSelectorOptionDTO>> {
            return localVarFp.getMainProjects(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get organizations list from MS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizations(options?: any): AxiosPromise<Array<FieldSelectorOptionDTO>> {
            return localVarFp.getOrganizations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects(options?: any): AxiosPromise<Array<FieldSelectorOptionDTO>> {
            return localVarFp.getProjects(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStores(options?: any): AxiosPromise<Array<FieldSelectorOptionDTO>> {
            return localVarFp.getStores(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MsShopFormSaveDTO} msShopFormSaveDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(msShopFormSaveDTO: MsShopFormSaveDTO, options?: any): AxiosPromise<MsShopFormDTO> {
            return localVarFp.update(msShopFormSaveDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MsShopControllerApi - object-oriented interface
 * @export
 * @class MsShopControllerApi
 * @extends {BaseAPI}
 */
export class MsShopControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MsShopControllerApi
     */
    public getForm(id: number, options?: AxiosRequestConfig) {
        return MsShopControllerApiFp(this.configuration).getForm(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MsShopControllerApi
     */
    public getGroups(options?: AxiosRequestConfig) {
        return MsShopControllerApiFp(this.configuration).getGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MsShopControllerApi
     */
    public getList(options?: AxiosRequestConfig) {
        return MsShopControllerApiFp(this.configuration).getList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MsShopControllerApi
     */
    public getMainContacts(options?: AxiosRequestConfig) {
        return MsShopControllerApiFp(this.configuration).getMainContacts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MsShopControllerApi
     */
    public getMainProjects(options?: AxiosRequestConfig) {
        return MsShopControllerApiFp(this.configuration).getMainProjects(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get organizations list from MS
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MsShopControllerApi
     */
    public getOrganizations(options?: AxiosRequestConfig) {
        return MsShopControllerApiFp(this.configuration).getOrganizations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MsShopControllerApi
     */
    public getProjects(options?: AxiosRequestConfig) {
        return MsShopControllerApiFp(this.configuration).getProjects(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MsShopControllerApi
     */
    public getStores(options?: AxiosRequestConfig) {
        return MsShopControllerApiFp(this.configuration).getStores(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MsShopFormSaveDTO} msShopFormSaveDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MsShopControllerApi
     */
    public update(msShopFormSaveDTO: MsShopFormSaveDTO, options?: AxiosRequestConfig) {
        return MsShopControllerApiFp(this.configuration).update(msShopFormSaveDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrdersControllerApi - axios parameter creator
 * @export
 */
export const OrdersControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersByFilter: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/apiship/orders/get-by-filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrdersControllerApi - functional programming interface
 * @export
 */
export const OrdersControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrdersControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrdersByFilter(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrdersByFilter(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrdersControllerApi - factory interface
 * @export
 */
export const OrdersControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrdersControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersByFilter(options?: any): AxiosPromise<void> {
            return localVarFp.getOrdersByFilter(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrdersControllerApi - object-oriented interface
 * @export
 * @class OrdersControllerApi
 * @extends {BaseAPI}
 */
export class OrdersControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersControllerApi
     */
    public getOrdersByFilter(options?: AxiosRequestConfig) {
        return OrdersControllerApiFp(this.configuration).getOrdersByFilter(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentsControllerApi - axios parameter creator
 * @export
 */
export const PaymentsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RoutesPaymentsRequest} routesPaymentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRoutesPayments: async (routesPaymentsRequest: RoutesPaymentsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'routesPaymentsRequest' is not null or undefined
            assertParamExists('setRoutesPayments', 'routesPaymentsRequest', routesPaymentsRequest)
            const localVarPath = `/api/apiship/payments/set`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(routesPaymentsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentsControllerApi - functional programming interface
 * @export
 */
export const PaymentsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {RoutesPaymentsRequest} routesPaymentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setRoutesPayments(routesPaymentsRequest: RoutesPaymentsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setRoutesPayments(routesPaymentsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentsControllerApi - factory interface
 * @export
 */
export const PaymentsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {RoutesPaymentsRequest} routesPaymentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRoutesPayments(routesPaymentsRequest: RoutesPaymentsRequest, options?: any): AxiosPromise<string> {
            return localVarFp.setRoutesPayments(routesPaymentsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentsControllerApi - object-oriented interface
 * @export
 * @class PaymentsControllerApi
 * @extends {BaseAPI}
 */
export class PaymentsControllerApi extends BaseAPI {
    /**
     * 
     * @param {RoutesPaymentsRequest} routesPaymentsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsControllerApi
     */
    public setRoutesPayments(routesPaymentsRequest: RoutesPaymentsRequest, options?: AxiosRequestConfig) {
        return PaymentsControllerApiFp(this.configuration).setRoutesPayments(routesPaymentsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PhpControllerApi - axios parameter creator
 * @export
 */
export const PhpControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PhpMsCreateProductRequestBody} phpMsCreateProductRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        msCreateProduct: async (phpMsCreateProductRequestBody: PhpMsCreateProductRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'phpMsCreateProductRequestBody' is not null or undefined
            assertParamExists('msCreateProduct', 'phpMsCreateProductRequestBody', phpMsCreateProductRequestBody)
            const localVarPath = `/api-php/ms/createProduct`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(phpMsCreateProductRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PhpControllerApi - functional programming interface
 * @export
 */
export const PhpControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PhpControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PhpMsCreateProductRequestBody} phpMsCreateProductRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async msCreateProduct(phpMsCreateProductRequestBody: PhpMsCreateProductRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhpCommonResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.msCreateProduct(phpMsCreateProductRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PhpControllerApi - factory interface
 * @export
 */
export const PhpControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PhpControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {PhpMsCreateProductRequestBody} phpMsCreateProductRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        msCreateProduct(phpMsCreateProductRequestBody: PhpMsCreateProductRequestBody, options?: any): AxiosPromise<PhpCommonResponseBody> {
            return localVarFp.msCreateProduct(phpMsCreateProductRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PhpControllerApi - object-oriented interface
 * @export
 * @class PhpControllerApi
 * @extends {BaseAPI}
 */
export class PhpControllerApi extends BaseAPI {
    /**
     * 
     * @param {PhpMsCreateProductRequestBody} phpMsCreateProductRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhpControllerApi
     */
    public msCreateProduct(phpMsCreateProductRequestBody: PhpMsCreateProductRequestBody, options?: AxiosRequestConfig) {
        return PhpControllerApiFp(this.configuration).msCreateProduct(phpMsCreateProductRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PointsControllerApi - axios parameter creator
 * @export
 */
export const PointsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PointsControllerRequest} pointsControllerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncPoints: async (pointsControllerRequest: PointsControllerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pointsControllerRequest' is not null or undefined
            assertParamExists('syncPoints', 'pointsControllerRequest', pointsControllerRequest)
            const localVarPath = `/api/apiship/points/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pointsControllerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PointsControllerApi - functional programming interface
 * @export
 */
export const PointsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PointsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PointsControllerRequest} pointsControllerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncPoints(pointsControllerRequest: PointsControllerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncPoints(pointsControllerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PointsControllerApi - factory interface
 * @export
 */
export const PointsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PointsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {PointsControllerRequest} pointsControllerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncPoints(pointsControllerRequest: PointsControllerRequest, options?: any): AxiosPromise<string> {
            return localVarFp.syncPoints(pointsControllerRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PointsControllerApi - object-oriented interface
 * @export
 * @class PointsControllerApi
 * @extends {BaseAPI}
 */
export class PointsControllerApi extends BaseAPI {
    /**
     * 
     * @param {PointsControllerRequest} pointsControllerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsControllerApi
     */
    public syncPoints(pointsControllerRequest: PointsControllerRequest, options?: AxiosRequestConfig) {
        return PointsControllerApiFp(this.configuration).syncPoints(pointsControllerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoutesControllerApi - axios parameter creator
 * @export
 */
export const RoutesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RoutesControllerRequest} routesControllerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRoutes: async (routesControllerRequest: RoutesControllerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'routesControllerRequest' is not null or undefined
            assertParamExists('setRoutes', 'routesControllerRequest', routesControllerRequest)
            const localVarPath = `/api/apiship/routes/set`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(routesControllerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoutesControllerApi - functional programming interface
 * @export
 */
export const RoutesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoutesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {RoutesControllerRequest} routesControllerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setRoutes(routesControllerRequest: RoutesControllerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setRoutes(routesControllerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoutesControllerApi - factory interface
 * @export
 */
export const RoutesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoutesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {RoutesControllerRequest} routesControllerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRoutes(routesControllerRequest: RoutesControllerRequest, options?: any): AxiosPromise<string> {
            return localVarFp.setRoutes(routesControllerRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoutesControllerApi - object-oriented interface
 * @export
 * @class RoutesControllerApi
 * @extends {BaseAPI}
 */
export class RoutesControllerApi extends BaseAPI {
    /**
     * 
     * @param {RoutesControllerRequest} routesControllerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutesControllerApi
     */
    public setRoutes(routesControllerRequest: RoutesControllerRequest, options?: AxiosRequestConfig) {
        return RoutesControllerApiFp(this.configuration).setRoutes(routesControllerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


