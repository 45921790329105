import React, { useState } from "react";
import { useAppDispatch } from "../store/store";
import { LockClosedIcon } from "@heroicons/react/solid";
import { login } from "../store/actions/generalActions";

export function Login() {
  const dispatch = useAppDispatch();

  const [userLogin, setUserLogin] = useState("");
  const [userPassword, setUserPassword] = useState("");

  const onSubmit = (event) => {
    event.preventDefault();
    if (userLogin.length && userPassword.length) {
      dispatch(login({ userLogin, userPassword }));
    }
  };

  return (
    <>
      <div className={"h-screen items-center w-full bg-gray-50"}>
        <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
          <div className="w-full max-w-md space-y-8">
            <form className="mt-8 space-y-6" onSubmit={onSubmit}>
              <div className="-space-y-px rounded-md shadow-sm">
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    Логин
                  </label>
                  <input
                    value={userLogin}
                    onChange={(event) => setUserLogin(event.target.value)}
                    required
                    className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Логин"
                  />
                </div>
                <div>
                  <label htmlFor="password" className="sr-only">
                    Пароль
                  </label>
                  <input
                    value={userPassword}
                    onChange={(event) => setUserPassword(event.target.value)}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Пароль"
                  />
                </div>
              </div>

              <div>
                <button
                  onClick={onSubmit}
                  type="submit"
                  className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <LockClosedIcon
                      className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                      aria-hidden="true"
                    />
                  </span>
                  Вход
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
