import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { Button, Form, notification, Skeleton, Space, Typography } from "antd";
import {
  MsShopControllerApiFactory,
  MsShopFormDTO,
  MsShopFormSaveDTO,
} from "../../../generated/backend";
import ShopFormItem, { fieldTypes } from "./ShopFormItem";

const { Title } = Typography;

const ShopForm: FC = () => {
  const { selectedShop } = useSelector((state: RootState) => state.shop);
  const [formLoading, setFormLoading] = useState(true);
  const [formData, setFormData] = useState<MsShopFormDTO>();
  const [initialData, setInitialData] = useState<MsShopFormDTO>();
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [abortController, setAbortController] = useState<AbortController>();

  const shopApi = MsShopControllerApiFactory();
  const [api, contextHolder] = notification.useNotification();

  const getFormData = async () => {
    setFormLoading(true);
    if (abortController) {
      abortController.abort();
      setAbortController(null);
    }
    const controller = new AbortController();
    setAbortController(controller);
    try {
      const res = await shopApi.getForm(selectedShop.id, {
        signal: controller.signal,
      });
      setFormData(res.data);
      setInitialData(res.data);
    } catch (err) {
      if (err.code === "ERR_CANCELED") {
        return;
      }
      api.error({
        message: "Возникла ошибка при получении данных.",
        description: err.message,
        placement: "bottomRight",
        duration: 0,
      });
    }
    setFormLoading(false);
  };

  const checkForm = () => {
    let isChanged = false;
    let isValid = true;
    for (const key in formData) {
      if (key && key !== "id" && key !== "message") {
        if (formData[key]?.value !== initialData[key]?.value) {
          isChanged = true;
        }
        if (formData[key]?.errors.length) {
          isValid = false;
        }
      }
    }
    setAllowSubmit(isValid && isChanged);
  };

  const onSelectorChange = (fieldName: string, newValue) => {
    if (newValue.value === initialData[fieldName].value) {
      newValue = initialData[fieldName];
    }
    setFormData({
      ...formData,
      [fieldName]: newValue,
    });
  };

  const updateShop = async () => {
    setFormLoading(true);
    const dataForUpdate: MsShopFormSaveDTO = {
      id: formData.id,
      msGroupId: formData.msGroup.value,
      msMainContactId: formData.msMainContact.value,
      msMainProjectId: formData.msMainProject.value,
      msOrganizationId: formData.msOrganization.value,
      msProjectId: formData.msProject.value,
      msStoreId: formData.msStore.value,
      name: formData.name.value,
    };

    try {
      const res = await shopApi.update(dataForUpdate);
      setFormData(res.data);

      if (res.data.message === "Update successful") {
        setInitialData(res.data);
        api.success({
          message: "Сохранено",
          placement: "bottomRight",
        });
      } else {
        api.error({
          message: "Не сохранено!",
          description: "Проверьте введённые данные",
          placement: "bottomRight",
        });
      }
    } catch (err) {
      api.error({
        message: "Сохранение не удалось",
        description: err.message,
        placement: "bottomRight",
        duration: 0,
      });
    }

    setFormLoading(false);
  };

  useEffect(() => {
    (async () => {
      if (!selectedShop) {
        return;
      }
      await getFormData();
    })();
  }, [selectedShop]);

  useEffect(() => {
    if (!formData) {
      return;
    }
    checkForm();
  }, [formData]);

  return (
    <div className={"p-5"}>
      {contextHolder}

      {formLoading ? (
        <Skeleton paragraph={{ rows: 11 }} />
      ) : (
        <Form
          colon={false}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 12 }}
          labelAlign={"left"}
          onFinish={updateShop}
          onReset={() => setFormData(initialData)}
        >
          <Title level={4}>{selectedShop.shippingName}</Title>
          <ShopFormItem
            label={"Название"}
            fieldType={fieldTypes.INPUT}
            value={formData?.name}
            onChange={(value, errors) =>
              setFormData({
                ...formData,
                name: { value: value, errors: errors },
              })
            }
          />
          <Title level={5}>Розничные параметры</Title>

          <ShopFormItem
            value={formData?.msOrganization}
            onChange={(value) => {
              onSelectorChange("msOrganization", value);
            }}
            label={"Организация"}
            fieldType={fieldTypes.SELECT}
            getOptions={shopApi.getOrganizations}
          />

          <ShopFormItem
            value={formData?.msGroup}
            onChange={(value) => {
              onSelectorChange("msGroup", value);
            }}
            label={"Отдел"}
            fieldType={fieldTypes.SELECT}
            getOptions={shopApi.getGroups}
          />

          <ShopFormItem
            value={formData?.msStore}
            onChange={(value) => {
              onSelectorChange("msStore", value);
            }}
            label={"Склад"}
            fieldType={fieldTypes.SELECT}
            getOptions={shopApi.getStores}
          />

          <ShopFormItem
            value={formData?.msProject}
            onChange={(value) => {
              onSelectorChange("msProject", value);
            }}
            label={"Проект"}
            fieldType={fieldTypes.SELECT}
            getOptions={shopApi.getProjects}
          />

          <Title level={5}>Основные параметры</Title>

          <ShopFormItem
            value={formData?.msMainContact}
            onChange={(value) => {
              onSelectorChange("msMainContact", value);
            }}
            label={"Контакт"}
            fieldType={fieldTypes.SELECT}
            getOptions={shopApi.getMainContacts}
          />

          <ShopFormItem
            value={formData?.msMainProject}
            onChange={(value) => {
              onSelectorChange("msMainProject", value);
            }}
            label={"Проект (осн.)"}
            fieldType={fieldTypes.SELECT}
            getOptions={shopApi.getMainProjects}
          />

          <Space>
            <Button htmlType={"submit"} disabled={!allowSubmit || formLoading}>
              Сохранить
            </Button>
            <Button htmlType={"reset"}>Сбросить</Button>
          </Space>
        </Form>
      )}
    </div>
  );
};

export default ShopForm;
