import React, { useEffect, useState } from "react";
import {
  MsShopControllerApiFactory,
  MsShopListItemDTO,
} from "../../generated/backend";
import { useAppDispatch } from "../../store/store";
import { setSelectedShop } from "../../store/slice/ShopSlice";
import { Affix, Input, List, notification } from "antd";
import ShopListItem from "./ShopListItem";

interface Props {
  selectedID: number;
  container: React.MutableRefObject<HTMLElement>;
}

const ShopList = (props: Props) => {
  const { selectedID, container } = props;
  const [shopList, setShopList] = useState<MsShopListItemDTO[]>([]);
  const [loading, setLoading] = useState(true);
  const [filterValue, setFilterValue] = useState("");
  const [list, setList] = useState([]);

  const api = MsShopControllerApiFactory();

  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await api.getList();
        setShopList(res.data);
        setList(res.data);
      } catch (e) {
        notificationApi.error({ message: "Не удалось получить список" });
      }
      setLoading(false);
    })();
  }, []);

  const onChange = (e) => {
    setFilterValue(e.target.value);
    if (e.target.value.length === 0) {
      setList(shopList);
    } else {
      const filtered = shopList.filter((shop) => {
        return shop.shippingName
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
      });
      setList(filtered);
    }
  };

  useEffect(() => {
    if (selectedID && shopList.length) {
      const selectedShop = shopList.find((shop) => shop.id === selectedID);
      dispatch(setSelectedShop(selectedShop));
    }
  }, [selectedID, shopList]);

  return (
    <>
      {contextHolder}
      <Affix target={() => container?.current}>
        <div className={"bg-white p-1"}>
          <Input value={filterValue} onChange={onChange} allowClear />
        </div>
      </Affix>
      <List
        dataSource={list}
        loading={loading}
        renderItem={(shop) => (
          <ShopListItem shop={shop} selectedID={selectedID} />
        )}
      />
    </>
  );
};

export default ShopList;
