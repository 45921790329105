import { Layout } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { fetchUserInfo } from "../../store/actions/generalActions";
import { Login } from "../Login";
import { RootState, useAppDispatch } from "../../store/store";
import { useSelector } from "react-redux";
import Header from "./Header";
import LayoutSider from "./LayoutSider";
import LayoutDrawer from "./LayoutDrawer";
import { useWindowSize } from "usehooks-ts";

const { Content } = Layout;

const PageLayout = () => {
  const [open, setOpen] = useState(false);

  const dispatch = useAppDispatch();
  const generalState = useSelector((state: RootState) => state.general);
  const token = localStorage.getItem("token");

  const hasSider = useWindowSize().width > 768;

  useEffect(() => {
    if (token || generalState.token) {
      dispatch(fetchUserInfo());
    }
  }, [generalState.token]);

  if (!generalState.auth || !token) {
    return <Login />;
  }

  return (
    <>
      <Layout className={"h-screen flex"}>
        <Header showButton={!hasSider} buttonClick={() => setOpen(true)} />
        <Layout hasSider={hasSider}>
          {hasSider && <LayoutSider />}
          <Content className={"overflow-y-auto"}>
            <Outlet />
          </Content>
        </Layout>
      </Layout>
      {!hasSider && <LayoutDrawer open={open} onClose={() => setOpen(false)} />}
    </>
  );
};

export default PageLayout;
