import React from "react";
import { Form, Space, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import ShopFormInput, { InputProps } from "./ShopFormInput";
import ShopFormSelect, { SelectProps } from "./ShopFormSelect";
import { FieldErrorDTOTypeEnum } from "../../../generated/backend";
const { Item } = Form;

export enum fieldTypes {
  SELECT = "select",
  INPUT = "input",
}

interface BaseProps<T = fieldTypes.SELECT | fieldTypes.INPUT> {
  label: string;
  fieldType: T;
}

type SelectItemProps = BaseProps<fieldTypes.SELECT> & SelectProps;

type InputItemProps = BaseProps<fieldTypes.INPUT> & InputProps;

type ItemProps<T> = T extends fieldTypes.SELECT
  ? SelectItemProps
  : InputItemProps;

const errorTooltipMap = new Map([
  [FieldErrorDTOTypeEnum.Empty, "Данное поле не может быть пустым."],
  [
    FieldErrorDTOTypeEnum.NotFound,
    "Выбранное раннее значение более недоступно. Очистите поле или выберите новое значение.",
  ],
  [
    FieldErrorDTOTypeEnum.NonUnique,
    "Выбранное значение используется другим магазином.",
  ],
  [
    FieldErrorDTOTypeEnum.Generic,
    "Что-то пошло не так, обратитесь к разработчику.",
  ],
]);

const ShopFormItem = (props: ItemProps<fieldTypes>) => {
  const { label, fieldType, value } = props;

  const renderField = () => {
    if (fieldType === fieldTypes.INPUT) {
      const { onChange } = props as InputItemProps;
      return <ShopFormInput onChange={onChange} value={value} />;
    } else {
      const { onChange, getOptions } = props as SelectItemProps;
      return (
        <ShopFormSelect
          value={value}
          getOptions={getOptions}
          onChange={onChange}
        />
      );
    }
  };

  const renderErrorMessage = () => (
    <>
      {value.errors.map((error, index) => (
        <Space key={index}>
          <p>{error.message}</p>
          <Tooltip
            title={`${errorTooltipMap.get(error.type)} ${error.details || ""}`}
          >
            <InfoCircleOutlined
              style={{ height: "24px", display: "flex", alignItems: "center" }}
              rev={""}
            />
          </Tooltip>
        </Space>
      ))}
    </>
  );

  return (
    <>
      <Item
        label={label}
        validateStatus={value.errors.length > 0 ? "error" : ""}
        help={renderErrorMessage()}
      >
        {renderField()}
      </Item>
    </>
  );
};

export default ShopFormItem;
