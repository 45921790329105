import { configureStore } from "@reduxjs/toolkit";
import generalReducer from "./slice/generalSlice";
import { useDispatch } from "react-redux";
import cmsReducer from "./slice/msSlice";
import ordersReducer from "./slice/ordersSlice";
import shopReducer from "./slice/ShopSlice";

export const store = configureStore({
  reducer: {
    general: generalReducer,
    ms: cmsReducer,
    orders: ordersReducer,
    shop: shopReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
