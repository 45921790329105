import React, { useEffect, useState } from "react";
import { MsShopListItemDTO } from "../../generated/backend";
import { List, Space, Typography, Tag } from "antd";
import { useNavigate } from "react-router-dom";
const { Item } = List;
import classNames from "classnames";

interface Props {
  shop: MsShopListItemDTO;
  selectedID: number;
}

const ShopListItem = (props: Props) => {
  const { shop, selectedID } = props;
  const [isSelected, setIsSelected] = useState(false);
  const navigate = useNavigate();
  const [warnings, setWarnings] = useState([]);

  useEffect(() => {
    if (selectedID === shop.id) {
      document.getElementById(String(shop.id)).scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [warnings]);

  useEffect(() => {
    setIsSelected(selectedID === shop.id);
  }, [selectedID]);

  useEffect(() => {
    setWarnings([]);
    if (shop) {
      for (const key in shop) {
        if (key !== "ip" && (shop[key] === null || shop[key] === "")) {
          setWarnings((prevState) => {
            const newState = [...prevState];
            newState.push(key);
            return newState;
          });
        }
      }
    }
  }, [shop]);

  const warningsMap = new Map([
    ["msProjectId", "Проект розница"],
    ["msStoreId", "Склад"],
    ["msGroupId", "Отдел"],
    ["msMainContactId", "Контакт осн.МС"],
    ["msOrganizationId", "Организация"],
    ["msMainProjectId", "Проект основной"],
    ["name", "Имя"],
  ]);

  return (
    <Item
      id={String(shop.id)}
      className={isSelected ? "bg-violet-100 pl-1" : "pl-1 hover:bg-violet-50"}
      onClick={() => {
        navigate(`${shop.id}`);
      }}
    >
      <Space direction={"vertical"}>
        <Typography.Text>{shop.shippingName}</Typography.Text>
        <Typography.Text className={"text-sm text-gray-500"}>
          {shop.name}
        </Typography.Text>
        <Space direction={"horizontal"} wrap>
          {warnings.map((warning, index) => (
            <Tag className={classNames("bg-violet-50")} key={index}>
              {warningsMap.get(warning)}
            </Tag>
          ))}
        </Space>
      </Space>
    </Item>
  );
};

export default ShopListItem;
