import { createSlice } from "@reduxjs/toolkit";

export interface OrdersState {
}

const initialState: OrdersState = {

}

export const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {

    },
})

export const { } = ordersSlice.actions

export default ordersSlice.reducer