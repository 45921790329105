import { createSlice } from "@reduxjs/toolkit";
import {fetchUserInfo, login} from "../actions/generalActions";

export interface GeneralState {
    //initial: boolean
    token: string
    username: string
    auth: boolean
}

const initialState: GeneralState = {
    username: "",
    //initial: true,
    token: localStorage.getItem('token'),
    auth: false,
}

export const generalSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        logout: (state) => {
            state.token = null;
            state.auth = false;
            state.username = "";
            localStorage.removeItem('token');
        },
    },
    extraReducers: {
        [fetchUserInfo.pending.type]: () => {

        },
        [fetchUserInfo.fulfilled.type]: (state, action) => {
            state.auth = true;
            state.username = action.payload;
        },
        [fetchUserInfo.rejected.type]: (state, action) => {
            state.auth = false;
            state.username = "";
            console.log(action.payload);
        },
        [login.fulfilled.type]: (state, action) => {
            state.auth = true;
            state.token = action.payload['access_token'];
        },
        [login.rejected.type]: (state, action) => {
            state.auth = false;
        }
    }
})

export const { logout } = generalSlice.actions

export default generalSlice.reducer