import {createAsyncThunk} from "@reduxjs/toolkit";
import {AuthControllerApiFactory} from "../../generated/backend";
//import alertService from "@rbiconcept/ring-ui/components/alert-service/alert-service";

const api = AuthControllerApiFactory();

export const fetchUserInfo = createAsyncThunk(
    'general/fetchUserInfo',
    async (_, {rejectWithValue})=> {
        try {
            const response = await api.getMe();
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

export const login = createAsyncThunk(
    'general/login',
    async (payload: {userLogin: string, userPassword: string}, {rejectWithValue}) => {
        const { userLogin, userPassword } = payload;
        try {
            const response = await api.userAuth({ userLogin, userPassword });
            localStorage.setItem('token', response.data['access_token']);
            return response.data;
        } catch (err) {
            //alertService.error(err.message, 4000)
            return rejectWithValue(err.message);
        }
    }
);