import React, { useRef, useState } from "react";
import {
  Input,
  Radio,
  Button,
  Typography,
  notification,
  Form,
  Space,
  List,
  Progress,
} from "antd";
import { MsOrderControllerApiFactory } from "../../generated/backend";

enum MsAccountType {
  MAIN = 0,
  RETAIL = 1,
}

interface FormState {
  orderNumber: string;
  selectedAccount: MsAccountType;
}

const MsOrders = () => {
  const [form] = Form.useForm<FormState>();
  const [responses, setResponse] = useState<string[]>([]);
  const [count, setCount] = useState(0);
  const [progressCount, setProgressCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const loadingStop = useRef(false);
  const response = [];

  const api = MsOrderControllerApiFactory();

  const normalizeValue = (value) => {
    if (!value) {
      return [];
    }
    const removeS = value.replaceAll("S", "");
    const removeDash = removeS.replaceAll("-", "");
    return removeDash.match(/\d+/g);
  };

  const setOrdersCount = (e) => {
    const ordersArray = normalizeValue(e.target.value);
    setCount(ordersArray.length);
  };

  const sendOrder = (values, selectedAccount, i) => {
    setProgressCount(i + 1);
    if (!values?.[i] || loadingStop.current) {
      setLoading(false);
      loadingStop.current = false;
      return;
    }
    api
      .exportOrder({
        orderId: Number(values[i]),
        msAccountIndex: selectedAccount,
      })
      .then((res) => {
        response.push(res.data);
        setResponse([...response]);
        i += 1;
      })
      .catch((err) => {
        loadingStop.current = true;
        notification.error({
          message: `Что-то пошло не так: ${err.message}`,
          placement: "bottomRight",
        });
      })
      .finally(() => sendOrder(values, selectedAccount, i));
  };

  const onFinish = (values) => {
    setLoading(true);
    const valueArray = normalizeValue(values.orderNumber);
    const selectedAccount = values.selectedAccount;
    const i = 0;
    sendOrder(valueArray, selectedAccount, i);
  };

  return (
    <div className={"px-5 py-2 w-full h-full"}>
      <Form
        layout={"vertical"}
        form={form}
        name={"orderExport"}
        onFinish={onFinish}
      >
        <Typography.Title level={4}>Экспорт заказов</Typography.Title>
        <label htmlFor={"orderNumber"}>Номер заказа</label>
        <Form.Item
          wrapperCol={{ span: 8 }}
          name={"orderNumber"}
          rules={[{ required: true, message: "Заполните поле" }]}
        >
          <Input.TextArea
            placeholder={"Номер заказа"}
            onChange={setOrdersCount}
          />
        </Form.Item>

        <label htmlFor={"selectedAccount"}>Аккаунт МС</label>
        <Form.Item
          name={"selectedAccount"}
          rules={[{ required: true, message: "Заполните поле" }]}
        >
          <Radio.Group>
            <Radio value={MsAccountType.MAIN}>Основной</Radio>
            <Radio value={MsAccountType.RETAIL}>Розничный</Radio>
          </Radio.Group>
        </Form.Item>

        <Typography.Paragraph>Заказов на экспорт: {count}</Typography.Paragraph>

        <Form.Item>
          <Button
            type={"primary"}
            htmlType={"submit"}
            disabled={loading}
            className={"bg-blue-500 hover:bg-blue-400 disabled:bg-gray-100"}
          >
            Экспорт
          </Button>
        </Form.Item>
      </Form>

      {!!responses.length && !loading && (
        <Button
          className={"mb-2"}
          onClick={() => navigator.clipboard.writeText(responses.join("\r\n"))}
        >
          Копировать лог
        </Button>
      )}

      {loading && (
        <Typography.Paragraph>
          {progressCount}/{count}
        </Typography.Paragraph>
      )}
      {loading && (
        <Progress percent={Math.round((progressCount * 100) / count)} />
      )}
      {loading && (
        <Button
          onClick={() => {
            loadingStop.current = true;
          }}
        >
          Прервать загрузку
        </Button>
      )}

      <Space
        className={"max-h-[30%] overflow-y-scroll flex-col w-full"}
        align={"start"}
      >
        <List
          dataSource={responses}
          renderItem={(item) => {
            return (
              <List.Item>
                <Typography.Paragraph>{item}</Typography.Paragraph>
              </List.Item>
            );
          }}
          split={false}
          locale={{ emptyText: " " }}
        />
      </Space>
    </div>
  );
};

export default MsOrders;
