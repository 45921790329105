import React from "react";
import "./../assets/css/index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PageLayout from "./Layout/PageLayout";
import { MsLayout, DemandSupply, MsOrders } from "./ms";
import { ShopLayout, ShopForm } from "./shops";
import { Apiship } from "./apiship";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<PageLayout />}>
          <Route path="/ms" element={<MsLayout />}>
            <Route path="/ms/demand-supply" element={<DemandSupply />} />
            <Route path="/ms/export-order" element={<MsOrders />} />
          </Route>
          <Route path="/shops" element={<ShopLayout />}>
            <Route path=":id" element={<ShopForm />} />
          </Route>
          <Route path="/shipping" element={<Apiship />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
